<template>
  <b-row class="match-height">
    <b-col cols="12">
      <b-card :title="this.$route.meta.title">
        <b-alert
          v-height-fade
          dismissible
          v-model="showDismissibleAlert"
          fade
          class="mb-2"
          variant="danger"
          v-id="showDismissibleAlert == true"
        >
          <div class="alert-body" v-if="errors.position != ''">
            <span>
              Uang harian dengan jabatan dan level yang dipilih sudah tersedia
            </span>
          </div>
          <div class="alert-body" v-else>
            <span>{{ messages }}</span>
          </div>
        </b-alert>
        <ValidationObserver ref="form" v-slot="{ handleSubmit }">
          <b-form @submit.prevent="handleSubmit(save)">
            <b-row>
              <b-col md="6">
                <ValidationProvider
                  name="Jabatan"
                  rules="required"
                  v-slot="{ errors }"
                  class="col-12"
                >
                  <b-form-group>
                    <label>
                      Jabatan <span class="fs-6 text-danger">*</span>
                    </label>
                    <v-select
                      v-model="model.position"
                      :options="jabatan"
                      :reduce="item => item.name"
                      label="name"
                    />
                    <p class="fs-6 text-danger">
                      {{ errors[0] }}
                    </p>
                  </b-form-group>
                </ValidationProvider>
                <p
                  class="fs-6 text-danger"
                  v-if="errors.position != ''"
                  style="margin-top: -10px"
                >
                  {{ errors.position[0] }}
                </p>
              </b-col>
              <b-col md="6">
                <ValidationProvider
                  name="level"
                  rules="required"
                  v-slot="{ errors }"
                  class="col-12"
                >
                  <b-form-group>
                    <label>
                      Level Kantor <span class="fs-6 text-danger">*</span>
                    </label>
                    <v-select
                      v-model="model.level"
                      :options="levels"
                      :reduce="item => `${item.idx},${item.slug}`"
                      label="name"
                    />
                    <p class="fs-6 text-danger">
                      {{ errors[0] }}
                    </p>
                  </b-form-group>
                </ValidationProvider>
                <p
                  class="fs-6 text-danger"
                  v-if="errors.level != ''"
                  style="margin-top: -10px"
                >
                  {{ errors.level[0] }}
                </p>
              </b-col>
              <b-col md="12">
                <ValidationProvider
                  name="BSU"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <b-form-group>
                    <label>BSU <span class="fs-6 text-danger">*</span></label>
                    <vue-numeric
                      v-model="model.total"
                      name="total"
                      @keypress.native="NumbersOnly"
                      class="form-control"
                      placeholder="Masukkan BSU"
                      currency="Rp"
                      separator="."
                    ></vue-numeric>
                    <p class="fs-6 text-danger">
                      {{ errors[0] }}
                    </p>
                  </b-form-group>
                </ValidationProvider>
                <!-- <p class="fs-6 text-danger" v-if="errors.total != ''" style="margin-top: -10px;">
                    {{ errors.total[0] }}
                  </p> -->
              </b-col>
            </b-row>
            <b-card-footer>
              <!-- submit and reset -->
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="primary"
                class="mr-1 float-right"
              >
                Simpan
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="reset"
                class="mr-1 float-right"
                variant="outline-secondary"
                :to="{ name: 'uang-harian' }"
              >
                Batal
              </b-button>
            </b-card-footer>
          </b-form>
        </ValidationObserver>
      </b-card>
    </b-col>
  </b-row>
</template>

<style>
.mx-datepicker {
  width: 100% !important;
}
</style>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormFile,
  BFormCheckbox,
  BForm,
  BButton,
  BCard,
  BCardFooter,
  BFormRadioGroup,
  BFormInvalidFeedback,
  BFormValidFeedback,
  BFormTextarea,
  BAlert,
  BImg,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import axios from '@axios'
import { heightFade } from '@core/directives/animations'
import Multiselect from 'vue-multiselect'

export default {
  components: {
    BCard,
    BCardFooter,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormFile,
    BFormCheckbox,
    BForm,
    BButton,
    BFormRadioGroup,
    vSelect,
    BFormInvalidFeedback,
    BFormValidFeedback,
    BFormTextarea,
    BAlert,
    BImg,
    Multiselect,
  },
  directives: {
    Ripple,
    'height-fade': heightFade,
  },
  data() {
    return {
      name: 'UsersForm',
      errors: {
        position: '',
        level: '',
        total: '',
      },
      showDismissibleAlert: false,
      config: {
        api: '/daily-trip-moneys',
        jabatan: '/misc/positions',
        level: '/misc/levels',
        redirect: 'uang-harian',
      },
      model: {
        position: '',
        level: '',
        total: '',
      },
      selectLevel: '',
      logos: null,
      logo_prev: null,
      confirmPass: null,
      error_link: true,
      messages: '',
      jabatan: [],
      levels: [],
    }
  },
  mounted() {
    let _ = this
    _.getJabatan()
    _.getLevel()
  },
  methods: {
    NumbersOnly(evt) {
      evt = evt ? evt : window.event
      var charCode = evt.which ? evt.which : evt.keyCode
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault()
      } else {
        return true
      }
    },
    getJabatan() {
      let _ = this
      axios.get(_.config.jabatan).then(response => {
        let _ = this
        _.jabatan = response.data.data
      })
    },
    getLevel() {
      let _ = this
      axios.get(_.config.level).then(response => {
        let _ = this
        _.levels = response.data.data.map(item => {
          item.idx = item.id
          delete item.id
          return item
        })
        if (this.$route.meta.action != 'store') {
          _.getData()
        }
      })
    },
    getData() {
      let _ = this
      axios.get(_.config.api + '/' + this.$route.params.id).then(response => {
        let _ = this
        const { position, level, slug, total } = response.data.data

        let currentSlug = slug
        if (!slug) {
          currentSlug = _.levels.find(({ idx }) => idx == level)?.slug
        }

        _.model = {
          position,
          level: `${level},${currentSlug}`,
          total,
        }
      })
    },
    save() {
      let _ = this
      const { position, level: modelLevel, total } = _.model
      const [level, slug] = modelLevel.split(',')
      const data = {
        position,
        total,
        level,
        slug,
      }
      if (this.$route.meta.action == 'store') {
        axios
          .post(_.config.api, data)
          .then(res => {
            _.$router.push({
              name: _.config.redirect,
              params: {
                event: 'success',
                title: 'Tambah Data Berhasil',
                text: 'Data baru berhasil ditambahkan',
              },
            })
          })
          .catch(e => {
            let vm = this
            vm.showDismissibleAlert = true
            if (typeof e.response.data.message === 'object') {
              vm.errors = e.response.data.data
            } else {
              vm.messages = e.response.data.message
              vm.errors = e.response.data.data
            }
          })
      } else {
        axios
          .put(_.config.api + '/' + _.$route.params.id, data)
          .then(res => {
            _.$router.push({
              name: _.config.redirect,
              params: {
                event: 'success',
                title: 'Ubah Data Berhasil',
                text: 'Data berhasil diubah',
              },
            })
          })
          .catch(e => {
            let vm = this
            vm.showDismissibleAlert = true
            if (typeof e.response.data.message === 'object') {
              vm.errors = e.response.data.data
            } else {
              vm.messages = e.response.data.message
              vm.errors = e.response.data.data
            }
          })
      }
    },
    customLabel({ name }) {
      if (name != null) {
        return `${name}`
      }
    },
  },
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
.vs__dropdown-toggle {
  min-width: 100% !important;
  max-width: 100% !important;
}
.v-select.vs--single .vs__selected {
  min-width: 100% !important;
  max-width: 100% !important;
}
</style>
